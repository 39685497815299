import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import styled from '@emotion/styled';
import Button from '@shared/ui/buttons/Button';
const Container = styled.div(props => ({
    display: 'flex',
    justifyContent: 'space-between',
    '&:not(:last-child)': {
        borderBottom: '1px solid #EFF1F3',
    },
    padding: `${props.theme.spacing_sizes.s * 3}px 0`,
}));
const NameContainer = styled.div(() => ({
    flex: '2',
}));
const ValueContainer = styled.div(() => ({
    flex: '2',
}));
const ButtonContainer = styled.div(() => ({
    flex: '1',
    textAlign: 'end',
}));
const SettingsRow = ({ name = null, value, ManageBtn = undefined, manageBtnProps = undefined, hideButton = false, }) => (_jsxs(Container, { children: [_jsx(NameContainer, { children: name }), _jsx(ValueContainer, { children: value }), _jsxs(ButtonContainer, { children: [ManageBtn || null, !hideButton && !ManageBtn && (_jsx(Button, Object.assign({ variant: 'tinted', color: 'primary', size: 'small' }, manageBtnProps)))] })] }));
export default SettingsRow;
