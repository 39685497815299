import { useDispatch } from 'react-redux';
import { useAppSelector } from '@app/store/Hooks';
import { closeSidebar as closeSidebarAction, PaymentMethodFormType, setPaymentMethodFormStep, updatePaymentMethodForm, } from '@entities/current-user';
import { useWizard } from '@shared/ui/misc/Wizard';
export var AddPaymentSteps;
(function (AddPaymentSteps) {
    AddPaymentSteps[AddPaymentSteps["Initial"] = 0] = "Initial";
    AddPaymentSteps[AddPaymentSteps["Final"] = 1] = "Final";
})(AddPaymentSteps || (AddPaymentSteps = {}));
export var EditPaymentSteps;
(function (EditPaymentSteps) {
    EditPaymentSteps[EditPaymentSteps["Initial"] = 0] = "Initial";
    EditPaymentSteps[EditPaymentSteps["Final"] = 1] = "Final";
    EditPaymentSteps[EditPaymentSteps["Delete"] = 2] = "Delete";
})(EditPaymentSteps || (EditPaymentSteps = {}));
const usePaymentAccounts = () => {
    const { goNext, goTo } = useWizard();
    const { paymentMethodFormType, paymentMethodFormStep } = useAppSelector(state => state.user);
    const dispatch = useDispatch();
    function closeForm() {
        dispatch(closeSidebarAction());
    }
    const updatePaymentMethod = (values) => {
        if (typeof paymentMethodFormStep === 'number') {
            dispatch(updatePaymentMethodForm(values));
            dispatch(setPaymentMethodFormStep(paymentMethodFormStep + 1));
            goNext();
        }
    };
    const addPaymentMethod = (values) => {
        if (typeof paymentMethodFormStep === 'number') {
            dispatch(updatePaymentMethodForm(values));
            dispatch(setPaymentMethodFormStep(paymentMethodFormStep + 1));
            goNext();
        }
    };
    const deletePaymentMethod = () => { };
    const goToNextStep = (values) => {
        switch (paymentMethodFormStep) {
            case 0:
                if (values && paymentMethodFormType === PaymentMethodFormType.AddPaymentMethod) {
                    addPaymentMethod(values);
                }
                else if (values && paymentMethodFormType === PaymentMethodFormType.EditPaymentMethod) {
                    updatePaymentMethod(values);
                }
                break;
            case 1:
                break;
            default:
                break;
        }
    };
    const returnToEdit = () => {
        if (paymentMethodFormType === PaymentMethodFormType.EditPaymentMethod) {
            goTo(EditPaymentSteps.Initial);
        }
    };
    const handleDelete = () => {
        if (paymentMethodFormType === PaymentMethodFormType.EditPaymentMethod) {
            deletePaymentMethod();
        }
    };
    const goToDeleteStep = () => {
        if (paymentMethodFormType === PaymentMethodFormType.EditPaymentMethod) {
            goTo(EditPaymentSteps.Delete);
        }
    };
    const finishForm = () => {
        closeForm();
    };
    return {
        goToNextStep,
        closeForm,
        returnToEdit,
        finishForm,
        goToDeleteStep,
        handleDelete,
        isLoading: false,
    };
};
export default usePaymentAccounts;
